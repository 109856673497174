@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// Vars
.TutorialHero {
  --TutorialHero-code-bg: var(--gray10);
  --TutorialHero-ellipse-bg: var(--gray90);
  --TutorialHero-ellipse-text-color: var(--gray20);
  --TutorialHero-heading-color: var(--color-text-minimum);
  --TutorialHero-item-color: var(--color-text-muted);
  --TutorialHero-number-color: var(--color-text-minimum);
  --TutorialHero-popup-bg: var(--white);
  --TutorialHero-popup-color: var(--color-text-muted);

  @include dark-mode {
    --TutorialHero-code-bg: var(--gray0);
    --TutorialHero-ellipse-bg: var(--gray30);
    --TutorialHero-ellipse-text-color: var(--gray80);
    --TutorialHero-popup-bg: var(--gray15);
  }
}

// Styles
.TutorialHero {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container);
  padding-bottom: 25px;
  padding-top: 40px;
  position: relative;
  text-align: center;

  @media (min-width: 1181px) {
    column-gap: 15px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 15px;
  }

  @media (max-width: 1180px) {
    display: flex;
    flex-direction: column;
    max-width: 775px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 775px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-Col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;

    &-Title,
    &-Description,
    &-Heading,
    &-List {
      margin-right: 20px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;
    }

    &-Title {
      font-weight: 700;
    }

    &-Description {
      font-size: 16px;
      line-height: 1.4;
      margin-top: 5px;
    }

    &-Heading {
      color: var(--TutorialHero-heading-color);
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 15px;
    }

    &-List {
      counter-reset: steps;
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;

      &_ellipsis {
        counter-reset: steps 3;
      }

      &-Item {
        color: var(--TutorialHero-item-color);
        font-size: 14px;
        padding: 3px 20px;

        &::before {
          color: var(--TutorialHero-number-color);
          content: counter(steps);
          counter-increment: steps;
          display: inline-block;
          margin-right: 5px;
          min-width: 10px;
        }
      }
    }

    &-Ellipsis {
      background: var(--TutorialHero-ellipse-bg);
      border: 0;
      border-radius: 3px;
      color: var(--TutorialHero-ellipse-text-color);
      cursor: pointer;
      font-size: 13px;
      margin-bottom: 5px;
      margin-left: 20px;
      margin-top: 5px;
      position: relative;

      &:hover,
      &:active,
      &:focus {
        .TutorialHero-Col-Ellipsis-Popup {
          opacity: 1;
          transform: none;
        }
      }

      &:focus {
        outline: 0;
      }

      &-Popup {
        background: var(--TutorialHero-popup-bg);
        border-radius: var(--border-radius);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05), 0 3px 9px rgba(0, 0, 0, 0.1);
        left: 35px;
        opacity: 0;
        padding: 10px 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transform: scale(0.95) translateY(-5px);
        transition: opacity 0.15s cubic-bezier(0.16, 1, 0.3, 1), transform 0.15s cubic-bezier(0.16, 1, 0.3, 1);
        white-space: nowrap;
        z-index: 100;

        .TutorialHero-Col-List-Item {
          color: var(--TutorialHero-popup-color);
        }
      }
    }

    &-Button {
      background: var(--recipe-button-color, get(color 'blue'));
      margin-left: 20px;
      margin-top: 10px;

      &:hover {
        background: var(--recipe-button-color-hover, #027ff1);
      }

      &:active {
        background: var(--recipe-button-color-active, #0271d6);
      }

      &:focus {
        box-shadow: 0 0 0 3px var(--recipe-button-color-focus, rgba(17, 140, 253, 0.25));
      }

      &:disabled {
        background: var(--recipe-button-color-disabled, #70bafe);
      }
    }
  }

  &-Image {
    border: 0;
    border-radius: var(--border-radius-lg);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: relative;

    @media (max-width: 1180px) {
      margin-top: 40px;
      max-width: 775px;
      width: 100%;
    }

    &-TutorialEditor-Nav {
      background: var(--TutorialHero-code-bg);
      display: flex;
      flex: 0 0 40px;
      justify-content: flex-start;
      padding-left: 10px;
      padding-right: 20px;
      width: 100%;

      &-Button:not(:first-child) {
        opacity: 0.5;
      }
    }

    :global {
      .CodeEditor {
        background: var(--TutorialHero-code-bg);
        flex: 1 1 auto;
        overflow: auto;
        text-align: left;
        white-space: pre;
        z-index: 0;

        .cm-lineNumber {
          box-sizing: border-box;
        }

        &.cm-s-material-palenight .CodeMirror,
        .cm-s-material-palenight.CodeMirror,
        .cm-s-material-palenight .CodeMirror-gutters {
          background: var(--TutorialHero-code-bg);
        }
      }

      .CodeMirror {
        font-size: 13px;
        height: 300px;
        position: relative;

        &::after {
          @include dark-mode {
            background: linear-gradient(rgba($gray0, 0), $gray0);
          }

          background: linear-gradient(rgba($gray10, 0), $gray10);
          bottom: 0;
          content: '';
          display: block;
          height: 50px;
          left: 0;
          position: absolute;
          right: 0;
          width: 100%;
          z-index: 10;
        }
      }
    }
  }
}
